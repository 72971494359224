import React, { useState, useEffect } from 'react';
import { fetchReferralCode, fetchUserInfo, fetchLeaderboard } from '../api/auth';
import toast, { Toaster } from 'react-hot-toast';
import Overlay from '../components/overlay';
import Stats from './stats';
import { useDisconnect } from "wagmi";

export default function Leaderboard() {
  const [token, setToken] = useState(null);
  const [userAddress, setUserAddress] = useState(''); 
  const [referralCode, setReferralCode] = useState('');
  const [refereesInvited, setRefereesInvited] = useState(0);
  const [raffleTicketsGained, setRaffleTicketsGained] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showRafferes, setShowRafferes] = useState(true);
  const [showStats, setShowStats] = useState(false);

  const { disconnect } = useDisconnect();
  const FRONTEND_URL = process.env.REACT_APP_FRONTEND_DOMAIN;

  useEffect(() => {
    const storedTokenData = localStorage.getItem('tokenData');
    if (storedTokenData) {
      const { access_token, expiryTime } = JSON.parse(storedTokenData);
      const currentTime = new Date().getTime();

      if (currentTime >= expiryTime) {
        disconnect();
        localStorage.removeItem('tokenData');
        setToken(null);
        toast.error('Session expired. Please log in again.');
      } else {
        setToken(access_token);
        setIsLoading(true);
        getReferralCode(access_token);
        loadUserInfo(access_token);
        loadLeaderboardData(access_token);
      }
    }
  }, []);

  const getReferralCode = async (token) => {
    try {
      const data = await fetchReferralCode(token);
      const referralCode = `${FRONTEND_URL}signup?referralCode=${data}`;
      setReferralCode(referralCode);
    } catch (error) {
      console.error('Failed to fetch referral code:', error);
      toast.error('Failed to fetch referral code');
    } finally {
      setIsLoading(false);
    }
  };

  const loadUserInfo = async (token) => {
    try {
      const data = await fetchUserInfo(token);
      setUserAddress(data.address); 
      setRefereesInvited(data.referees_invited || 0);
      setRaffleTicketsGained(data.points || 0);
    } catch (error) {
      console.error('Failed to fetch user info:', error);
      toast.error('Failed to fetch user info');
    }
  };

  const loadLeaderboardData = async (token) => {
    try {
      const data = await fetchLeaderboard(token, 10);
      setLeaderboardData(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Failed to fetch leaderboard data:', error);
      toast.error('Failed to fetch leaderboard data');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralCode);
    toast.success('Copied to clipboard');
  };

  return (
    <div className='relative'>
      {isLoading && <Overlay text="Loading referral link..." />}
      <div className='container mx-auto border border-[#bb5547] rounded-2xl bg-black bg-opacity-55 mt-5 pt-12 pb-28 px-32'>
        <Toaster />
        <div className='md:flex md:space-x-14 md:justify-center pb-16'>
          <p className={`md:text-[40px] text-[25px] font-semibold ${showRafferes && 'text-[#FBB03B]'} cursor-pointer`} onClick={() => { setShowRafferes(true); setShowStats(false) }}>REFEREES</p>
          <p className={`md:text-[40px] text-[25px] font-semibold ${showStats && 'text-[#FBB03B]'} cursor-pointer`} onClick={() => { setShowStats(true); setShowRafferes(false) }}>LEADERBOARD STATS</p>
        </div>

        {
          showRafferes &&
          <div className='lg:flex space-x-10 justify-between space-y-8 md:space-y-14 lg:space-y-0'>
            <div className="flex flex-col space-y-24">
              <div className="md:flex md:space-x-5 items-center space-y-3 md:space-y-0">
                <img src="/images/icon-share.png" alt="" className='w-[50px] mx-auto' />
                <p className='text-[18px] text-center md:text-left'>Send an invite. Share your referral link to start earning!</p>
              </div>
              <div className="flex">
                <div className="md:flex md:space-x-5 items-center space-y-3 md:space-y-0">
                  <img src="/images/icon-badge.png" alt="" className='w-[50px] mx-auto' />
                  <p className='text-[18px] text-center md:text-left'>Send an invite. Share your referral link to start earning!</p>
                </div>
              </div>
              <div className="flex">
                <div className="md:flex md:space-x-5 items-center space-y-3 md:space-y-0">
                  <img src="/images/icon-gift.png" alt="" className='w-[50px] mx-auto' />
                  <p className='text-[18px] text-center md:text-left'>Send an invite. Share your referral link to start earning!</p>
                </div>
              </div>
            </div>
            <div className="w-[3px] bg-[#FBB03B] hidden lg:block"></div>
            <div className="flex flex-col justify-center lg:w-[30%] space-y-10">
              <div>
                <p className='text-[30px] font-semibold text-center mb-1'>Referral Link</p>
                <div className="relative">
                  <input
                    className="block w-full p-4 ps-10 pr-12 text-sm text-white-900 border-white border-2 rounded-lg bg-transparent"
                    placeholder="Link"
                    value={referralCode}
                    readOnly
                  />
                  <button type="submit" className="text-white absolute end-2.5 bottom-2.5 px-4 py-2" onClick={copyToClipboard}>
                    <img className='w-4' src="/images/icon-copy.png" alt="" />
                  </button>
                </div>
              </div>
              <div>
                <p className='text-[30px] text-[#FBB03B]  font-semibold text-center'>{refereesInvited}</p>
                <p className='text-[30px] font-extralight tracking-wider text-center'>Referees Invited</p>
              </div>

              <div>
                <p className='text-[30px] text-[#FBB03B]  font-semibold text-center'>{raffleTicketsGained}</p>
                <p className='text-[30px] font-extralight tracking-wider text-center'>Raffle Tickets Gained</p>
              </div>
            </div>
          </div>
        }

        {
          showStats &&
          <div>
            <Stats userAddress={userAddress} leaderboardData={leaderboardData} /> {/* Pass userAddress and leaderboardData to Stats component */}
          </div>
        }
      </div>
    </div>
  );
}
