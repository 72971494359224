import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { useWeb3ModalContext } from "../providers/Web3ModalProvider";
import { useSignMessage, useAccount, useDisconnect } from "wagmi";
import { loginWithWeb3, linkEmail, handleSocials } from "../api/auth";
import toast, { Toaster } from 'react-hot-toast';
import Overlay from "../components/overlay";

export default function ConectWalletSection() {
    const { disconnect } = useDisconnect();
    const { setSignedMessage, setIsSigned, modal } = useWeb3ModalContext();
    const { address, isDisconnected } = useAccount();
    const { signMessage, data: signatureData, error: signError } = useSignMessage();

    const [token, setToken] = useState(null);
    const [email, setEmail] = useState("");
    const [socialHandles, setSocialHandles] = useState({
        twitter: "",
        discord: "",
        telegram: ""
    });
    const [referralCode, setReferralCode] = useState(null);
    const [isEmailLinking, setIsEmailLinking] = useState(false);
    const [isSocialsLinking, setIsSocialsLinking] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // New state for loading

    const ERRORCODES = [400, 401, 402, 403, 404];

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const refCode = params.get('referralCode');
        if (refCode) {
            setReferralCode(refCode);
        }
    }, []);

    useEffect(() => {
        const storedTokenData = localStorage.getItem('tokenData');
        if (storedTokenData) {
            const { access_token, expiryTime } = JSON.parse(storedTokenData);
            const currentTime = new Date().getTime();

            if (currentTime >= expiryTime) {
                // Token has expired, disconnect
                disconnect();
                localStorage.removeItem('tokenData');
                setToken(null);
                toast.error('Session expired. Please log in again.');
            } else {
                // Token is valid
                setToken(access_token);
            }
        } else {
            if (!token) {
                signMessage({ message: address });
            }
        }
    }, [address, signMessage, token, disconnect]);


    useEffect(() => {
        if (isDisconnected) {
            localStorage.removeItem('tokenData');
            setToken(null);
        }
    }, [isDisconnected]);

    useEffect(() => {
        if (signatureData) {
            const handleLogin = async () => {
                setIsLoading(true); // Start loading
                try {
                    const response = await loginWithWeb3(signatureData, address, referralCode);
                    const currentTime = new Date().getTime();
                    const expiryTime = currentTime + 90 * 24 * 60 * 60 * 1000; // 90 days in milliseconds
                    if (response.data && ERRORCODES.includes(response.data.statusCode)) {
                        console.error(response.data.message);
                        toast.error(response.data.message);
                        return;
                    }

                    const tokenData = {
                        access_token: response.access_token,
                        expiryTime: expiryTime
                    };

                    setToken(response.access_token);
                    localStorage.setItem('tokenData', JSON.stringify(tokenData));
                    toast.success('Login successful');
                } catch (error) {
                    console.error("Failed to login:", error);
                    toast.error("Failed to login");
                } finally {
                    setIsLoading(false); // Stop loading
                }
            };
            handleLogin();
        }
    }, [signatureData, address, setSignedMessage, setIsSigned, referralCode]);


    const handleEmailLink = async () => {
        if (!token) {
            console.error("User is not authenticated");
            toast.error('User is not authenticated');
            signMessage({ message: address });
            return;
        }

        setIsEmailLinking(true);
        try {
            const response = await linkEmail(token, email);
            if (ERRORCODES.includes(response.data.statusCode)) {
                toast.error(response.data.message);
                return;
            }
            toast.success('Email linked successfully');
        } catch (error) {
            console.error("Failed to link email:", error);
            toast.error('Failed to link email');
        } finally {
            setIsEmailLinking(false);
        }
    };

    const handleSocialsLink = async () => {
        if (!token) {
            console.error("User is not authenticated");
            toast.error('User is not authenticated');
            signMessage({ message: address });
            return;
        }

        setIsSocialsLinking(true);
        try {
            const socialsData = [
                { platform: 'discord', handle: socialHandles.discord.startsWith('@') ? socialHandles.discord.substring(1) : socialHandles.discord },
                { platform: 'twitter', handle: socialHandles.twitter.startsWith('@') ? socialHandles.twitter.substring(1) : socialHandles.twitter },
                { platform: 'telegram', handle: socialHandles.telegram.startsWith('@') ? socialHandles.telegram.substring(1) : socialHandles.telegram },
            ];

            const response = await handleSocials(token, socialsData);

            if (ERRORCODES.includes(response.data.statusCode)) {
                toast.error(response.data.message);
                return;
            }
            toast.success('Socials linked successfully');
        } catch (error) {
            console.error("Failed to link socials:", error);
            toast.error('Failed to link socials');
        } finally {
            setIsSocialsLinking(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSocialHandles({
            ...socialHandles,
            [name]: value.startsWith('@') ? value.substring(1) : value
        });
    };

    const openModal = () => {
        modal.open();
    }

    return (
        <div className='relative'>
            {isLoading && <Overlay text="Logging in..." />}
            {isEmailLinking || isSocialsLinking ? <Overlay text="Processing..." /> : null}
            <div className='container border border-[#bb5547] rounded-2xl bg-black bg-opacity-55 mt-5 pt-12 pb-28 lg:px-32 mx-auto overflow-auto'>
                <Toaster />
                <div className='my-10'>
                    <p className='text-[28px] font-extralight text-center'>
                        Participate in our Referral Program, make a contribution into the growth of
                        Bounty Temple's Community and get a chance to win exclusive rewards!
                    </p>
                </div>

                <div className='flex flex-col space-y-reverse gap-x-5 gap-y-10 lg:flex-row flex-wrap justify-center content-evenly'>
                    <Step title="STEP #1" subtitle="Connect your wallet">
                        {address ? (
                            <button
                                className="w-[239px] h-[64px] bg-transparent mx-auto flex items-center justify-center !mt-10"
                                onClick={openModal}
                            >
                                <p className='text-white'>{`${address.substring(0, 6)}...${address.substring(address.length - 4)}`}</p>
                            </button>
                        ) : (
                            <>
                                <img src="/images/icon-metamask.png" alt="MetaMask" className='mx-auto w-60 cursor-pointer' />
                                <img src="/images/icon-wallet-connect.png" alt="Wallet Connect" className='mx-auto w-64 cursor-pointer' />
                                <button className="w-[239px] h-[64px] bg-transparent mx-auto flex !mt-10">
                                    <img className='object-fill' src="/images/connectBtn.png" alt="Connect" onClick={openModal} />
                                </button>
                            </>
                        )}
                    </Step>

                    <Step title="STEP #2" subtitle="Follow our socials">
                        <div className='flex justify-center space-x-8 !my-2'>
                            <img src="/images/icon-x.png" alt="Twitter" className='w-14 cursor-pointer' />
                            <img src="/images/icon-discord.png" alt="Discord" className='w-14 cursor-pointer' />
                            <img src="/images/icon-telegram.png" alt="Telegram" className='w-14 cursor-pointer' />
                        </div>
                        <div className='flex flex-col justify-center space-y-4 py-3'>
                            <p className="text-[20px] font-semibold text-center">
                                Enter you ids for checking
                            </p>
                            <input
                                type="text"
                                name="twitter"
                                placeholder="Enter your Twitter ID"
                                className="w-full px-3 py-2 placeholder-gray-500 bg-transparent border-2 border-white rounded-2xl"
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="discord"
                                placeholder="Enter your Discord ID"
                                className="w-full px-3 py-2 placeholder-gray-500 bg-transparent border-2 border-white rounded-2xl"
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="telegram"
                                placeholder="Enter your Telegram ID"
                                className="w-full px-3 py-2 placeholder-gray-500 bg-transparent border-2 border-white rounded-2xl"
                                onChange={handleInputChange}
                            />
                        </div>
                        <button className="w-[239px] h-[64px] bg-transparent mx-auto flex !mt-10" onClick={handleSocialsLink}>
                            <img className='object-fill' src="/images/btnCheck.png" alt="Check" />
                        </button>
                    </Step>

                    <Step title="STEP #3" subtitle="Link your email">
                        <div className='flex flex-col justify-center space-y-6'>
                            <img src="/images/icon-mail.png" alt="Mail" className='w-16 cursor-pointer mx-auto' />
                            <input type="text" placeholder="Enter your email address" className="w-full px-3 py-2 placeholder-gray-500 bg-transparent border-2 border-white rounded-2xl" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <button className="w-[239px] h-[64px] bg-transparent mx-auto flex !mt-10" onClick={handleEmailLink}>
                            <img className='object-fill' src="/images/btn-confirm.png" alt="Confirm" />
                        </button>
                    </Step>
                </div>
            </div>
        </div>
    );
}

const Step = ({ title, subtitle, children }) => (
    <div className='border-2 space-y-5 lg:space-y-5 border-[#FBB03B] rounded-3xl p-8 w-[370px] flex flex-col justify-between min-h-[511px]'>
        <div>
            <p className='text-[28px] font-semibold text-center tracking-wide'>{title}</p>
            <p className='text-[26px] font-semibold text-center'>{subtitle}</p>
        </div>
        {children}
    </div>
);
