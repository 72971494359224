
import React, { useState } from 'react';

const CloseIcon = ({ size = 24, color = 'currentColor' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        stroke={color}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
);


export const QuestModal = ({ isOpen, onClose, title, description, imgUrl, onAccept, onReject }) => {
    if (!isOpen) return null;

    return (
        <div className='fixed inset-0 flex items-center justify-center p-4 z-50 backdrop-brightness-50'>
            <div className='bg-white bg-opacity-80 p-10 rounded-2xl shadow-lg max-w-md w-full relative space-y-5'>
    
                <div className='text-center1 text-black text-sm'>
                    <p>Quest Name: <b>{title}</b></p>
                    <p>Task Description:  <b>{description}</b></p>
                </div>

                <div>
                    <img className='object-fill  w-[100%] h-[200px]' src={imgUrl} alt="" />
                </div>

                <div className='flex text-black justify-center items-center gap-x-3'>
                    <b>Points: </b>
                    <input
                        type="number"
                        className='h-10 w-20 border border-gray-400 rounded-2xl text-black px-4'
                    />
                </div>

                <div className='sm:flex text-black justify-center items-center gap-x-3'>
                    <button className='bg-[#FE0603] rounded-xl px-8 py-2'>
                        Reject
                    </button>

                    <button className='bg-[#8AC73C] rounded-xl px-8 py-2'>
                        Accept
                    </button>
                </div>

                <button
                    onClick={onClose}
                    className='absolute top-1 right-3 text-gray-500 '
                >
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
};