import React from 'react';

export default function Stats({ userAddress, leaderboardData }) {
    const userData = leaderboardData.find(user => user.address === userAddress);

    return (
        <div>
            <p className='text-gray-100 text-center text-[20px]'>You have</p>
            <div className='lg:flex justify-center space-y-8 lg:space-y-0 lg:space-x-24 my-7'>
                <div>
                    <p className='text-[36px] text-center font-bold'>
                        {userData ? userData.referees_invited : 0}
                    </p>
                    <p className='text-[40px] text-center'>
                        Referees
                    </p>
                </div>

                <div>
                    <p className='text-[36px] text-center font-bold'>
                        {userData ? userData.points : 0}
                    </p>
                    <p className='text-[40px] text-center'>
                        Points
                    </p>
                </div>
            </div>

            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right">
                    <tbody className='text-[18px]'>
                        {leaderboardData.map((user, index) => (
                            <tr key={user._id} className="border-2 border-[#fbb03b]">
                                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {index + 1}. {user.address}
                                </th>
                                <td className="px-6 py-4">
                                    {user.points}
                                </td>
                                <td className="px-6 py-4">
                                    {user.referees_invited}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className='text-[18px]'>
                        <tr className="bg-bg-row bg-cover lg:bg-contain">
                            <th scope="row" className="px-6 py-3 text-black font-bold">Total</th>
                            <td className="px-6 py-3 text-black font-bold">{leaderboardData.reduce((total, user) => total + user.points, 0)}</td>
                            <td className="px-6 py-3 text-black font-bold">{leaderboardData.reduce((total, user) => total + user.referees_invited, 0)}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}
