import React, { useState } from 'react'
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Menu, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Checkbox, FormControlLabel
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useParams } from 'react-router-dom';

export default function Userdetails() {
    const { id } = useParams();

    console.log({ id });

    const [data, setData] = useState([{
        type: 'test',
        action: 'action',
        item: 'item',
        quantity: 'quantity',
    }]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className='container mx-auto bg-gray-300  text-black '>
            <div>
                <div className='md:flex md:space-x-10 items-center px-10'>
                    <img src="/images/bountyTemple.png" alt="" className='w-[200px]' />
                    <h1 className='text-black text-4xl font-bold mb-5 md:mb-0 '>
                        REFERRAL PROGRAM DASHBOARD
                    </h1>
                </div>
            </div>
            <div className='flex bg-gray-300  text-black px-16 '>
                <div className='py-5 px-10 space-y-10 border-r-2 border-gray-500'>
                    <div>
                        <b className='text-sm'>Wallet Address</b>
                        <p>0xeedqwkdnqwk12</p>
                    </div>

                    <div>
                        <b className='text-2xl'>Referrals</b>
                        <p className='text-xl'>10000000000</p>
                    </div>

                    <div>
                        <b className='text-2xl'>Points</b>
                        <p className='text-xl'>10000000000</p>
                    </div>

                    <div className='flex items-center justify-center'>
                        <img src="/images/diamond.png" alt="" srcset="" className='h-20 w-20' />
                        <p className='text-xl'>10000000000</p>
                    </div>

                    <div className='flex items-center justify-center'>
                        <img src="/images/diamond.png" alt="" srcset="" className='h-20 w-20' />
                        <p className='text-xl'>10000000000</p>
                    </div>

                    <div className='flex items-center justify-center'>
                        <img src="/images/diamond.png" alt="" srcset="" className='h-20 w-20' />
                        <p className='text-xl'>10000000000</p>
                    </div>
                    <div className='flex items-center justify-center'>
                        <img src="/images/diamond.png" alt="" srcset="" className='h-20 w-20' />
                        <p className='text-xl'>10000000000</p>
                    </div>
                </div>

                <div className=' p-5 space-y-10 w-[100%]'>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow className='!shadow-none bg-gray-300'>
                                    {['Type', 'Action', 'Item', 'Quantity', 'More'].map(header => (
                                        <TableCell className='!font-bold' key={header}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={index} className='!shadow-none bg-gray-300'>
                                        <TableCell>{row.type}</TableCell>
                                        <TableCell>{row.action}</TableCell>
                                        <TableCell>{row.item}</TableCell>
                                        <TableCell>{row.quantity}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={(event) => handleMenuClick(event, row)}>
                                                <MoreVertIcon style={{ color: 'black' }} />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl)}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Option1</MenuItem>
                                                <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Option2</MenuItem>
                                                <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Option3</MenuItem>
                                                <MenuItem sx={{ color: 'black' }} onClick={handleMenuClose}>Option4</MenuItem>
                                            </Menu>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}
