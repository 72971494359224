import React from 'react';

const StyledBtn = ({ text, style, onClick }) => {
    return (
        <button className={`${style}`} onClick={onClick}>
            {text}
        </button>
    )
}

export default StyledBtn;