import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3ModalContext } from "../providers/Web3ModalProvider";

const DropdownMenu = ({ title, items, isMobile }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const showDropdown = () => {
        setIsDropdownOpen(true);
    };

    const hideDropdown = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className="relative">
            <button
                onClick={handleDropdownClick}
                onMouseEnter={handleDropdownClick}
                className="text-[#CA8DFF] flex items-center focus:outline-none"
            >
                {title}
                {
                    !isDropdownOpen ?
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="#CA8DFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                        </svg>
                        :
                        <svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="#CA8DFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5l4-4 4 4" />
                        </svg>

                }
            </button>
            <div className={`${isDropdownOpen ? 'block' : 'hidden'} ${isMobile ? 'mt-2 bg-black rounded-md bg-opacity-50 p-3' : 'p-3 mt-0 absolute right-[-110] w-56 bg-opacity-80 origin-top-right bg-black rounded-md'}`}
                onMouseLeave={hideDropdown}
            >
                {items.map((item, index) => (
                    <a key={index} href={item.href}
                        target="_blank"
                        className="block px-4 py-2 text-sm text-white hover:bg-[#2C292E] hover:border hover:border-yellow-300"
                    >
                        {item.label}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default function Navbar() {
    const { modal } = useWeb3ModalContext();
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        modal.open();
    }

    const howToPlaydropdownItems = [
        { label: 'Gameplay', href: 'https://bountytemple.com/game-play.html' },
        { label: 'P2E Evolution', href: 'https://bountytemple.com/evolution.html' },
    ];

    const communitydropdownItems = [
        { label: 'Discord', href: 'https://discord.com/invite/bountytemple' },
        { label: 'X.com', href: 'https://x.com/BountyTemple' },
        { label: 'Telegram', href: 'https://t.me/bountytemple' },
        { label: 'Medium', href: 'https://medium.com/@bountytemple' },
        { label: 'Facebook', href: 'https://www.facebook.com/BountyTempleP2EE' },
        { label: 'Instagram', href: 'https://www.instagram.com/bountytemple/' },
        { label: 'Tiktok', href: 'https://www.tiktok.com/@bountytemple' },
    ];

    const aboutDropdownItems = [
        { label: 'Meet the Team', href: 'https://bountytemple.com/' },
        { label: 'Partners', href: 'https://bountytemple.com/' },
        { label: 'Roadmap', href: 'https://bountytemple.com/' },
        { label: 'Whitepaper', href: 'https://bountytemple.gitbook.io/whitepaper-v1' },
    ];

    return (
        <div>
            <nav className="">
                <div className="">
                    <div className="">
                        <div className="hidden lg:flex gap-3 justify-around py-16 items-center ">
                            <div className='flex justify-evenly items-center w-[90%] xl:w-[70%] gap-x-3 xl:gap-x-0'>
                                <Link to="/" className='text-[#CA8DFF] cursor-pointer'>HOME</Link>
                                <DropdownMenu title="HOW TO PLAY" items={howToPlaydropdownItems} isMobile={false} />
                                <DropdownMenu title="COMMUNITY" items={communitydropdownItems} isMobile={false} />
                                <DropdownMenu title="ABOUT" items={aboutDropdownItems} isMobile={false} />

                                <a href="https://bountytemple.com/careers-vacancies.html" target="_blank" rel="noopener noreferrer" className='text-[#CA8DFF] cursor-pointer text-center'>CAREERS</a>
                                {/* <AnchorLink href="#getting-started" className='text-[#CA8DFF] cursor-pointer'>GETTING STARTED</AnchorLink> */}
                                <a href="https://store.bountytemple.com/" target="_blank" rel="noopener noreferrer" className='text-[#CA8DFF] cursor-pointer p-0 m-0 overflow-clip text-center'>GETTING STARTED</a>
                                <a href="https://store.bountytemple.com/" target="_blank" rel="noopener noreferrer" className='text-[#CA8DFF] cursor-pointe text-center'>NFT BUNDLES</a>
                                <a href="#" className='text-[#9062b8] cursor-pointer text-center '>MARKETPLACE</a>
                                {/* <Link to="/admin" className='text-[#CA8DFF] cursor-pointer'>ADMIN PANEL</Link> */}
                            </div>
                            <button className="w-[179px] h-[54px] bg-transparent" onClick={openModal}>
                                <img className='object-fill' src="/images/connectBtn.png" alt="" />
                            </button>
                        </div>

                        <div className="lg:hidden flex items-center justify-between px-2 pb-2 flex-wrap ">
                            <img src="/images/bountyTemple.png" className="w-[120px] " alt="" srcset="" />
                            <button onClick={() => setIsOpen(!isOpen)} className="mobile-menu-button mb-8 mt-7 focus:outline-none">
                                <svg className="w-16 h-16 text-gray-700 hover:text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>

                <div className={`${isOpen ? 'flex flex-col' : 'hidden'} lg:hidden space-y-5 z-50 px-4 pb-10`}>
                    <Link to="/" className='text-[#CA8DFF] cursor-pointer'>HOME</Link>
                    <DropdownMenu title="HOW TO PLAY" items={howToPlaydropdownItems} isMobile={true} />
                    <DropdownMenu title="COMMUNITY" items={communitydropdownItems} isMobile={true} />
                    <DropdownMenu title="ABOUT" items={aboutDropdownItems} isMobile={true} />

                    <a href="https://bountytemple.com/careers-vacancies.html" target="_blank" rel="noopener noreferrer" className='text-[#CA8DFF] cursor-pointer'>CAREERS</a>
                    {/* <AnchorLink href="#getting-started" className='text-[#CA8DFF] cursor-pointer'>GETTING STARTED</AnchorLink> */}
                    <a href="https://store.bountytemple.com/" target="_blank" rel="noopener noreferrer" className='text-[#CA8DFF] cursor-pointer p-0 m-0'>GETTING STARTED</a>
                    <a href="https://store.bountytemple.com/" target="_blank" rel="noopener noreferrer" className='text-[#CA8DFF] cursor-pointe'>NFT BUNDLES</a>
                    <a href="#" className='text-[#9062b8] cursor-pointer'>MARKETPLACE</a>
                    {/* <Link to="/admin" className='text-[#CA8DFF] cursor-pointer'>ADMIN PANEL</Link> */}
                </div>
            </nav>
        </div>
    );
}
