import React, { useEffect, useState } from 'react';
import Quest from './quest';
import { fetchMasterQuests, fetchQuests, fetchUserInfo, fetchIncompleteQuests } from '../api/auth';
import toast, { Toaster } from 'react-hot-toast';
import Overlay from '../components/overlay';
import { useAccount, useDisconnect } from "wagmi";
import { QuestDetail } from './QuestDetail';

export default function QuestSection() {
    const [token, setToken] = useState(null);
    const [quests, setQuests] = useState([]);
    const [pointsAccumulated, setPointsAccumulated] = useState(0);
    const [questsCompleted, setQuestsCompleted] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedQuest, setSelectedQuest] = useState(null);
    // const [inCompleteQuestIds, setInCompleteQuestIds] = useState([]);

    const { disconnect } = useDisconnect();

    const { address } = useAccount();

    // const fetchInCompleteQuest = async () => {
    //     const response = await fetchIncompleteQuests();
    //     console.log("response", response);
    //     if (response && response.length > 0) {
    //         const userQuests = response.filter(quest => quest.address === address);
    //         const subQuestIds = []
    //         userQuests.map(subQuest => {
    //             subQuest.incompleteQuests.map(incompleteQuest => {
    //                 subQuestIds.push(incompleteQuest.quest_id._id);
    //             })
    //         });
    //         setInCompleteQuestIds(subQuestIds);
    //     } else {
    //         setInCompleteQuestIds([]);
    //     }
    // }
    // useEffect(() => {
    //     fetchInCompleteQuest();
    // }, []);

    useEffect(() => {
        const storedTokenData = localStorage.getItem('tokenData');
        if (storedTokenData) {
            const { access_token, expiryTime } = JSON.parse(storedTokenData);
            const currentTime = new Date().getTime();

            if (currentTime >= expiryTime) {
                disconnect();
                localStorage.removeItem('tokenData');
                setToken(null);
                toast.error('Session expired. Please log in again.');
            } else {
                setToken(access_token);
                setIsLoading(true);
                loadQuests(access_token);
                loadUserInfo(access_token);
            }
        }
    }, []);

    const loadQuests = async (token) => {
        try {
            const data = await fetchMasterQuests(token);
            setQuests(data);
        } catch (error) {
            console.error('Failed to fetch quests:', error);
            toast.error('Failed to fetch quests');
        } finally {
            setIsLoading(false);
        }
    };

    const loadUserInfo = async (token) => {
        try {
            const data = await fetchUserInfo(token);
            setQuestsCompleted(data.quests_completed.length);
            setPointsAccumulated(data.points);
        } catch (error) {
            console.error('Failed to fetch user info:', error);
            toast.error('Failed to fetch user info');
        }
    };

    const handleQuestClick = (quest) => {
        setSelectedQuest(quest);
    };

    const closeQuestDetail = () => {
        setSelectedQuest(null);
    };

    const calculatePoints = (subQuestArray) => {
        let points = 0;
        subQuestArray.forEach((quest) => {
            points += quest.points;
        });
        return points;
    }

    return (
        <div className='relative'>
            {isLoading && <Overlay text="Loading quests..." />}
            <div className='relative container mx-auto border border-[#bb5547] rounded-2xl bg-black bg-opacity-55 mt-5 pb-28 overflow-auto'>
                <Toaster />
                {selectedQuest && (
                    <QuestDetail quest={selectedQuest} onClose={closeQuestDetail} />
                )}
                <div className='flex flex-col lg:flex-row lg:space-x-10 min-h-[490px] space-y-10 lg:space-y-0 pt-20 mx-32'>
                    <div className="flex flex-col space-y-10 self-center">
                        <div>
                            <p className='text-[30px] text-[#FBB03B] text-center font-semibold'>{pointsAccumulated}</p>
                            <p className='text-[30px] tracking-wider text-center'>Points Accumulated</p>
                        </div>
                        <div>
                            <p className='text-[30px] text-[#FBB03B] text-center font-semibold'>{questsCompleted}</p>
                            <p className='text-[30px] tracking-wider text-center'>Quests Completed</p>
                        </div>
                    </div>
                    <div className="w-[3px] bg-[#FBB03B]"></div>
                    <div className="flex flex-col space-y-8 w-full">
                        {quests.length > 0 ? (
                            quests.map((quest) => (
                                <Quest
                                    key={quest._id}
                                    badgeText={quest.badgeText || 'NEW'}
                                    badgeStyle='bg-[#8CC63F] px-8 text-[16px] font-semibold text-black rounded-3xl'
                                    statusText={quest.isNew ? 'NEW' : 'ONGOING'}
                                    statusStyle='bg-white px-5 xs:px-12 text-[12px] xs:text-[16px] font-semibold text-black rounded-3xl'
                                    title={quest.title}
                                    description={quest.description}
                                    points={calculatePoints(quest.quest_id)}
                                    onQuestClick={() => handleQuestClick(quest)}
                                />
                            ))
                        ) : (
                            <p className='text-[18px] text-center'>No quests available.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}