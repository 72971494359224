import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';

const Sidebar = ({ setActiveTab, currentTab }) => {
    return (
        <List component="nav" style={{ width: '250px', height: '100vh', paddingTop: '20px' }}>
            {/* <Typography variant="h6" align="center" style={{ marginBottom: '20px', color: 'purple' }}>
                Bounty Temple
            </Typography> */}
            {['User Database', 'Avatars', 'Referral', 'Quest Tracker', 'Incomplete Quests', 'Set Timer'].map((text, index) => (
                <ListItem button key={text} onClick={() => setActiveTab(index)}>
                    <ListItemText primary={text} 
                     sx={{ '& .MuiTypography-root': { 
                        color: currentTab == index  ? '#C700FF' : 'black',
                        paddingLeft: 3
                      } }} />
                </ListItem>
            ))}
        </List>
    );
};

export default Sidebar;