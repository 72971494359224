import React, { useEffect, useState } from 'react';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { fetchIncompleteQuests, fetchUserInfo, updateQuestion } from '../api/auth';
import { useAccount } from 'wagmi';

const CloseIcon = ({ size = 24, color = 'currentColor' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        stroke={color}
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
        />
    </svg>
);

export const OpenIcon = ({ openIconColor }) => (
    <svg
        width={25}
        height={25}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M41.667 20.8334V39.5834C41.667 40.136 41.4475 40.6659 41.0568 41.0566C40.6661 41.4473 40.1362 41.6668 39.5837 41.6668H10.417C9.86446 41.6668 9.33455 41.4473 8.94385 41.0566C8.55315 40.6659 8.33366 40.136 8.33366 39.5834V10.4167C8.33366 9.86421 8.55315 9.33431 8.94385 8.94361C9.33455 8.55291 9.86446 8.33342 10.417 8.33342H29.167C29.7195 8.33342 30.2494 8.11392 30.6401 7.72322C31.0308 7.33252 31.2503 6.80262 31.2503 6.25008C31.2503 5.69755 31.0308 5.16764 30.6401 4.77694C30.2494 4.38624 29.7195 4.16675 29.167 4.16675H10.417C8.75939 4.16675 7.16968 4.82523 5.99758 5.99733C4.82547 7.16943 4.16699 8.75915 4.16699 10.4167V39.5834C4.16699 41.241 4.82547 42.8307 5.99758 44.0028C7.16968 45.1749 8.75939 45.8334 10.417 45.8334H39.5837C41.2413 45.8334 42.831 45.1749 44.0031 44.0028C45.1752 42.8307 45.8337 41.241 45.8337 39.5834V20.8334C45.8337 20.2809 45.6142 19.751 45.2235 19.3603C44.8328 18.9696 44.3029 18.7501 43.7503 18.7501C43.1978 18.7501 42.6679 18.9696 42.2772 19.3603C41.8865 19.751 41.667 20.2809 41.667 20.8334Z"
            fill={openIconColor}
        />
        <path
            d="M43.7501 4.16675H37.5001C36.9475 4.16675 36.4176 4.38624 36.0269 4.77694C35.6362 5.16764 35.4167 5.69755 35.4167 6.25008C35.4167 6.80262 35.6362 7.33252 36.0269 8.11392C36.4176 8.11392 36.9475 8.33341 37.5001 8.33341H38.7292L23.5209 23.5209C23.1286 23.9132 22.9082 24.4453 22.9082 25.0001C22.9082 25.5549 23.1286 26.0869 23.5209 26.4792C23.9132 26.8715 24.4453 27.0919 25.0001 27.0919C25.5549 27.0919 26.0869 26.8715 26.4792 26.4792L41.6667 11.2709V12.5001C41.6667 13.0526 41.8862 13.5825 42.2769 13.9732C42.6676 14.3639 43.1975 14.5834 43.7501 14.5834C44.3026 14.5834 44.8325 14.3639 45.2232 13.9732C45.6139 13.5825 45.8334 13.0526 45.8334 12.5001V6.25008C45.8334 5.69755 45.6139 5.16764 45.2232 4.77694C44.8325 4.38624 44.3026 4.16675 43.7501 4.16675Z"
            fill={openIconColor}
        />
    </svg>
);

// Checkbox Component
const Checkbox = ({ description, isChecked, setIsChecked, questId, disabled }) => {
    const openIconColor = isChecked ? '#75708B' : '#FBB03B';
    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        setIsChecked(checked, questId);
    };
    return (
        <div className='flex items-center gap-x-3 p-2'>
            <div className="inline-flex items-center">
                <label className={`flex items-center cursor-pointer relative ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                    <input
                        type="checkbox"
                        className={`peer h-9 w-9 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-[#CA8DFF] bg-transparent 
            ${disabled ? 'border-gray-300 bg-gray-200' : 'border-[#CA8DFF] bg-transparent'} 
            ${disabled ? 'opacity-50' : ''}`}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        disabled={disabled}
                    />
                    <span className="absolute text-green-600 opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth="1"
                        >
                            <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </span>
                </label>
            </div>
            <p className='w-[185px] leading-tight'>{description}</p>
            <OpenIcon openIconColor={openIconColor} />
        </div>
    );
};
export const QuestDetail = ({ quest, onClose }) => {
    const [isShareBtnChecked, setIsShareBtnChecked] = useState(false);
    const [isSubmitBtnEnabled, setIsSubmitBtnEnabled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [linkInputs, setLinkInputs] = useState({});
    const [checkedQuests, setCheckedQuests] = useState([]);
    const [inCompleteQuestIds, setInCompleteQuestIds] = useState([]);
    const [uploadedFileUrls, setUploadedFileUrls] = useState({});
    const [completedQuests, setCompletedQuests] = useState([]); // Store completed quests for the connected user

    const { address } = useAccount();

    const fetchInCompleteQuest = async () => {
        const response = await fetchIncompleteQuests();
        if (response && response.length > 0) {
            const userQuests = response.filter(quest => quest.address === address);
            const subQuestIds = [];
            userQuests.map(subQuest => {
                subQuest.incompleteQuests.map(incompleteQuest => {
                    subQuestIds.push(incompleteQuest.quest_id._id);
                });
            });
            setInCompleteQuestIds(subQuestIds);
        } else {
            setInCompleteQuestIds([]);
        }
    };

    useEffect(() => {
        fetchInCompleteQuest();
    }, []);

    useEffect(() => {
        // Fetch user info and store completed quests
        const loadUserInfo = async () => {
            try {
                const userInfo = await fetchUserInfo();
                setCompletedQuests(userInfo.quests_completed || []);
            } catch (error) {
                console.error('Failed to fetch user info:', error);
            }
        };

        loadUserInfo();
    }, []);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const uploadToS3 = async (event, questId) => {
        const file = event.target.files[0];
        if (file) {
            const s3 = new S3Client({
                region: process.env.REACT_APP_AWS_REGION,
                credentials: {
                    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
                },
            });
            const params = {
                Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
                Key: file.name,
                Body: file,
                ContentType: file.type,
            };

            try {
                const command = new PutObjectCommand(params);
                await s3.send(command);
                const fileUrl = `https://${process.env.REACT_APP_AWS_S3_BUCKET_NAME}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${file.name}`;
                setUploadedFileUrls({ ...uploadedFileUrls, [questId]: fileUrl }); // Store the uploaded file URL by quest ID
            } catch (error) {
                console.error("Error uploading file to S3:", error);
                alert("There was an error uploading your file.");
            }
        }
    };

    const handleFileUpload = (event, questId) => {
        uploadToS3(event, questId);
        setIsSubmitBtnEnabled(true);
    };

    const handleSubmit = async () => {
        // Submit for all checked quests
        const submitPromises = checkedQuests.map(async (questId) => {
            const data = await updateQuestion(questId, {
                file_url: linkInputs[questId] ? [linkInputs[questId]] : [uploadedFileUrls[questId]]
            });
            return data;
        });

        try {
            await Promise.all(submitPromises);
            setIsModalOpen(true);

            // Add a 2-second delay before closing the modal
            setTimeout(() => {
                onClose(); // Close the modal after 2 seconds
            }, 2000);
        } catch (error) {
            console.error('Error submitting quests:', error);
        }
    };


    const handleCheckboxChange = (isChecked, questId) => {
        if (isChecked) {
            setCheckedQuests([...checkedQuests, questId]);
        } else {
            setCheckedQuests(checkedQuests.filter(id => id !== questId));
        }
    };

    return (
        <div className='absolute w-[100%] h-[100%] bg-black bg-opacity-80 space-y-2 sm:space-y-10 content-center rounded-2xl z-20'>
            {isModalOpen && (
                <div className='fixed inset-0 flex items-center justify-center p-4 z-50'>
                    <div className='bg-black bg-opacity-80 p-10 rounded-2xl shadow-lg max-w-md w-full relative border border-[#bb5547] space-y-5'>
                        <p>Your submission will be reviewed by an admin soon!</p>
                        <button onClick={closeModal} className='absolute top-1 right-3 text-gray-500'>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            )}

            <div className='w-[70%] mx-auto space-y-5 text-center'>
                <div className='flex flex-col'>
                    <div className='self-end'>
                        <button onClick={onClose}>
                            <CloseIcon />
                        </button>
                    </div>
                    <p className='text-[20px] font-bold break-words whitespace-normal'>
                        {quest.name} Title
                    </p>
                    <p className='break-words whitespace-normal'>
                        {quest.description}
                    </p>
                </div>
            </div>

            {quest.quest_id.length > 0 ? quest.quest_id.map((questId) => {
                const isQuestCompleted = completedQuests.some(
                    (completedQuest) => completedQuest.quest_id === questId._id && completedQuest.given
                );

                return (
                    <div key={questId._id} className="flex flex-col items-center">
                        <Checkbox
                            description={questId.description}
                            isChecked={checkedQuests.includes(questId._id)}
                            setIsChecked={(checked, questId) => handleCheckboxChange(checked, questId)}
                            questId={questId._id}
                            disabled={inCompleteQuestIds.includes(questId._id)}
                        />

                        {/* Show 'Approved' if the quest is completed */}
                        {isQuestCompleted ? (
                            <p>Approved</p>
                        ) : (
                            <>
                                {checkedQuests.includes(questId._id) && (
                                    <>
                                        <div className='flex justify-around sm:justify-center sm:gap-x-20'>
                                            <input
                                                value={linkInputs[questId._id] || ''}
                                                type="text"
                                                name="url"
                                                placeholder="Enter your link"
                                                className="px-3 py-2 placeholder-gray-500 bg-transparent border-2 border-white rounded-2xl outline-none"
                                                onChange={(e) => setLinkInputs({ ...linkInputs, [questId._id]: e.target.value })}
                                            />
                                        </div>
                                        <div className='flex justify-around sm:justify-center sm:gap-x-20'>
                                            <label className="relative inline-block">
                                                <div className="bg-btn-primary w-48 h-24 bg-contain bg-center bg-no-repeat text-white font-bold text-center leading-24 cursor-pointer flex items-center justify-center">
                                                    UPLOAD
                                                </div>
                                                <input
                                                    type="file"
                                                    onChange={(e) => handleFileUpload(e, questId._id)}
                                                    className="absolute inset-0 opacity-0 cursor-pointer"
                                                />
                                            </label>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {/* Display 'Submitted' if the quest has already been completed */}
                        {inCompleteQuestIds.includes(questId._id) && <p>Submitted</p>}
                    </div>
                );
            }) : <p style={{ textAlign: 'center' }}>No sub question for this quest</p>}

            <div className='flex justify-around sm:justify-center sm:gap-x-20'>
                <button
                    className={`${isSubmitBtnEnabled ? 'bg-btn-primary' : 'bg-btn-disabled'} relative w-48 h-24 bg-contain bg-center bg-no-repeat text-white font-bold flex items-center justify-center`}
                    onClick={handleSubmit}
                    disabled={checkedQuests.length === 0}
                >
                    SUBMIT
                </button>
            </div>
        </div>
    );
};
