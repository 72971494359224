export default function Quest({ badgeStyle, badgeText, statusStyle, statusText, title, description, points, onQuestClick, }) {

    return (
        <div className='relative flex flex-col sm:flex-row space-y-5 sm:space-y-0 border border-[#FBB03B] items-center justify-between p-5 z-10 overflow-auto sm:overflow-visible'>
            <div className={`absolute top-[-13px] left-7 ${badgeStyle} hidden sm:block`}>
                {badgeText}
            </div>
            <div className={`sm:absolute  sm:top-[-13px] sm:right-7 ${statusStyle}`}>
                {statusText}
            </div>
            <div className=' w-full sm:w-[150px]'>
                <p className='text-[14px] xs:text-[20px] tracking-wider font-bold truncate'>{title}</p>
                <p className='text-[14px] xs:text-[20px] tracking-wider font-light mt-[-7px] truncate'>{description}</p>
            </div>
            <div className='w-[120px]'>
                <p className='text-[22px] text-[#FBB03B] font-semibold text-center'>{points} pts</p>
            </div>
            <div className=''>
                <p className={`text-[20px] ${statusText === 'NEW' ? 'text-[#FBB03B]' : 'text-white'}  font-semibold text-center cursor-pointer`} onClick={onQuestClick}>
                    Quest Info
                </p>
            </div>
        </div>
    );
}
